import api from '../api';
import Swal from 'sweetalert2';
import { enqueueSnackbar } from 'notistack';
import { decryptData } from 'src/hooks/common/useEncryption';

const COMPANY_API_URL = `${process.env.REACT_APP_CURRENT_COMPANY_API_URL}`;

// Permission Details
const getPermissionDetails = async () => {
  const userId = decryptData('userId')
  const userRoleId = decryptData('userRoleId')

  const response = await api.actionHandler({
    url: `${COMPANY_API_URL}/Permissions?searchType=permissions&userId=${userId}&userRoleId=${userRoleId}`,
    method: 'GET',
  });
  //@ts-ignore
  return response?.data?.data;
};

// Check Permission Details
const checkPermission = async (systemName, handleAction = null) => {
  try {
    const permissionDetails = await getPermissionDetails();
    const { userRole } = permissionDetails || {};
    const { permissions, isSystemRole } = userRole || {};
    const hasPermission = isSystemRole || permissions?.some(permission => permission.systemName === systemName && permission.status);

    if (hasPermission) {
      if (handleAction) {
        handleAction();
      }
      return true;
    } else {
      if (handleAction) {
        enqueueSnackbar('Access Denied', { variant: 'error' });
      } else {
        Swal.fire({
          icon: 'warning',
          title:'Warning',
          text: 'Access Denied'
        });
      }
      return false;
    }
  } catch (error) {
    console.error(error);
    return false;
  }
};




export { getPermissionDetails, checkPermission };
