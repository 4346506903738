import { FC, useState,useEffect, createContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getBranchList } from 'src/api/common/dropdownList';
type HeaderContext = {
  company: string;
  branch: string;
  setCompany: any;
  setBranch: any;
  profileDetails: any;
  setProfileDetails: any;
  permissionDetails: any;
  setPermissionDetails: any;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const HeaderContext = createContext<HeaderContext>({} as HeaderContext);

export const HeaderProvider: FC = ({ children }) => {
  const [company, setCompany] = useState('');
  const [profileDetails, setProfileDetails] = useState<any>(null);
  const [permissionDetails, setPermissionDetails] = useState<any>([]);

  const [branch, setBranch] = useState('');

  // Get Branch List
  const { data: branchList } = useQuery<any>({
    queryKey: ['branchList', company],
    queryFn: () => getBranchList(company),
    enabled: !!company,
  });
  
  useEffect(() => {
    if (branchList && branchList.length > 0) {
      setBranch(branchList[0].id);
    }
  }, [branchList]);

  return (
    <HeaderContext.Provider
      value={{
        company,
        setCompany,
        branch,
        setBranch,
        profileDetails,
        setProfileDetails,
        permissionDetails,
        setPermissionDetails,
      }}
    >
      {children}
    </HeaderContext.Provider>
  );
};
